import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { IRatingDescriptionModalProps } from "../../Interfaces/IRatingDescriptionModalProps";
import "../../styles/ratingDescriptionModal.css";

const RatingDescriptionModal = (props: IRatingDescriptionModalProps) => {
  const [show, setShow] = useState<boolean>(props.show);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const onCancelHandler = () => {
    setShow(false);
    props.onCancel();
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      <Modal show={show} onHide={onCancelHandler} centered size="lg">
        <Modal.Body>
          <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
              <table className="table-bordered m-auto table align-middle">
                <tbody>
                  <tr>
                    <td className="col-3">Not Eligible</td>
                    <td className="text-start">
                      Not meeting performance expectations, hired after June 30,
                      {" " + currentYear}, was on PIP at any time in
                      {" " + currentYear}
                    </td>
                  </tr>
                  <tr>
                    <td>Target</td>
                    <td className="text-start">
                      Meeting performance expectations in all areas (customer
                      orientation, results orientation, productivity, safety,
                      quality, job knowledge, problem solving, commitment,
                      initiative, and teamwork)
                    </td>
                  </tr>
                  <tr>
                    <td>High</td>
                    <td className="text-start">
                      Meeting performance expectations in all areas and
                      exceeding in some areas (customer orientation, results
                      orientation, productivity, safety, quality, job knowledge,
                      problem solving, commitment, initiative, and teamwork)
                    </td>
                  </tr>
                  <tr>
                    <td>Highest</td>
                    <td className="text-start">
                      Exceeding performance expectations in all areas (customer
                      orientation, results orientation, productivity, safety,
                      quality, job knowledge, problem solving, commitment,
                      initiative, and teamwork)
                    </td>
                  </tr>
                </tbody>
              </table>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RatingDescriptionModal;
