import React, { useContext } from "react";
import { Container, Navbar, NavbarBrand, NavbarText } from "reactstrap";
import { Link } from "react-router-dom";
import { userContext } from "./context/UserContext";

import "../styles/navMenu.css";

const Logo: string = require("../assets/PoweredByIT.svg").default;

export const NavMenu = () => {
  const user = useContext(userContext);

  return (
    <header>
      <Navbar
        className="navbar-expand-sm ng-white border-bottom box-shadow mb-3 justify-between"
        light
      >
        <Container fluid>
          <NavbarBrand tag={Link} to="/" style={{ maxWidth: "650px" }}>
            <img
              src={Logo}
              className="d-inline-block img-fluid align-top"
              alt="North Wind Logo"
            />
          </NavbarBrand>
          <NavbarText className="float-right">Hello, {user?.name}</NavbarText>
        </Container>
      </Navbar>
    </header>
  );
};

// export class NavMenu extends Component {
//   static displayName = NavMenu.name;

//   constructor(props) {
//     super(props);

//     this.toggleNavbar = this.toggleNavbar.bind(this);
//     this.state = {
//       collapsed: true,
//     };
//   }

//   toggleNavbar() {
//     this.setState({
//       collapsed: !this.state.collapsed,
//     });
//   }

//   render() {
//     return (
//       <header>
//         <Navbar
//           className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
//           container
//           light
//         >
//           <NavbarBrand tag={Link} to="/" className="brandText">
//             <Logo />
//           </NavbarBrand>
//           <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
//         </Navbar>
//       </header>
//     );
//   }
// }
