import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { INotEligibleModalProps } from "../../Interfaces/INotEligibleModalProps";
import { IRateEmployee } from "../../Interfaces/IRateEmployee";
import "../../styles/commentModal.css";

const possibleReasons = [
  "Has left/is leaving NW",
  "Is not meeting performance expectations",
  "Has been on PIP this CY",
];

const NotEligibleModal = (props: INotEligibleModalProps) => {
  const [show, setShow] = useState<boolean>(props.show);
  const [selectedItem, setSelectedItem] = useState<IRateEmployee>(
    props.selectedItem
  );

  const [reason, setReason] = useState<string>(props.selectedItem.reason ?? "");
  const [isOther, setIsOther] = useState<boolean>(
    !!reason && !possibleReasons.includes(reason)
  );

  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setSelectedItem(props.selectedItem);
  }, [props.selectedItem]);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const onCancelHandler = () => {
    setShow(false);
    props.onCancel();
  };

  useEffect(() => {
    if (isOther) return;

    const findReason = reason
      ? possibleReasons.includes(reason)
        ? reason
        : "Other"
      : "Has left/is leaving NW";

    setReason(findReason);
  }, [isOther, reason]);

  const onSaveHandler = () => {
    if (!reason) {
      setHasError(true);
      return;
    }

    setIsLoading(true);
    props.onSubmit(reason);
    setIsLoading(false);
    setShow(false);
  };

  const onCommentChangeHandler = (event) => {
    setReason(event.target.value);
  };

  const onReasonDropdownChangeHandler = (event) => {
    if (event.target.value !== "Other") {
      setIsOther(false);
      setReason(event.target.value);
      return;
    }

    setIsOther(true);
    setReason("");
  };

  return (
    <>
      <Modal show={show} onHide={onCancelHandler} backdrop="static" centered>
        <Modal.Header closeButton>{selectedItem.nameLabel}</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                For employees rated "Not Eligible," select a reason in the
                dropdown below.
              </Form.Label>
              {hasError && (
                <Form.Label className="text-danger">
                  You must write a reason before clicking "Save."
                </Form.Label>
              )}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Select
                onChange={onReasonDropdownChangeHandler}
                defaultValue={isOther ? "Other" : reason}
              >
                <option value="Has left/is leaving NW">
                  Has left/is leaving NW
                </option>
                <option value="Is not meeting performance expectations">
                  Is not meeting performance expectations
                </option>
                <option value="Has been on PIP this CY">
                  Has been on PIP this CY
                </option>
                <option value="Other">Other</option>
              </Form.Select>

              {isOther && (
                <Form.Control
                  as="textarea"
                  rows={5}
                  autoFocus
                  required
                  value={reason}
                  onChange={onCommentChangeHandler}
                  className="commentBox mt-3"
                />
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancelHandler}>
            {isLoading ? "Loading..." : "Cancel"}
          </Button>
          <Button variant="primary" onClick={onSaveHandler}>
            {isLoading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotEligibleModal;
