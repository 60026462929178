import * as React from "react";
import { useEffect, useState, createContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
interface IUser {
    email: string;
    name: string;
    bamboohrId: string;
}

export let userContext = createContext<IUser>(null);

export const UserContext = ({ children }) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState<IUser>(null);

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            oktaAuth
                .getUser()
                .then((info: any) => {
                    setUserInfo(info);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
        userContext = createContext(userInfo);
    }, [authState, oktaAuth]);
     
    return (  
        <userContext.Provider value={userInfo}>
            <>
                <>{children}</>
            </>
        </userContext.Provider>
    );
};
