import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { ICommentModalProps } from "../../Interfaces/ICommentModalProps";
import { IRateEmployee } from "../../Interfaces/IRateEmployee";
import "../../styles/commentModal.css";

const CommentModal = (props: ICommentModalProps) => {
  const [show, setShow] = useState<boolean>(props.show);
  const [selectedItem, setSelectedItem] = useState<IRateEmployee>(
    props.selectedItem
  );
  const [comment, setComment] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setSelectedItem(props.selectedItem);
  }, [props.selectedItem]);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    setComment(props.selectedItem.comments!);
  }, [props.selectedItem.comments]);

  const onCancelHandler = () => {
    setShow(false);
    props.onCancel();
  };

  const onSaveHandler = () => {
    if (!comment) {
      setHasError(true);
      return;
    }

    setIsLoading(true);
    props.onSubmit(comment);
    setIsLoading(false);
    setShow(false);
  };

  const onCommentChangeHandler = (event) => {
    setComment(event.target.value);
  };

  return (
    <>
      <Modal show={show} onHide={onCancelHandler} backdrop="static" centered>
        <Modal.Header closeButton>{selectedItem.nameLabel}</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                For employees rated "Highest," fill out the comments box below
                with a 2-3 sentence explanation for this rating.
              </Form.Label>
              {hasError && (
                <Form.Label className="text-danger">
                  You must write a comment before clicking "Save."
                </Form.Label>
              )}
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                <i>Comments </i>
                <span className="required">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                autoFocus
                required
                value={comment}
                onChange={onCommentChangeHandler}
                className="commentBox"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancelHandler}>
            {isLoading ? "Loading..." : "Cancel"}
          </Button>
          <Button variant="primary" onClick={onSaveHandler}>
            {isLoading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommentModal;
