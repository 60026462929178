import React from "react";

export const CellRender = (props) => {
  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ""];

  const additionalProps =
    cellField && cellField === inEditField
      ? {
          ref: (td) => {
            const input = td && td.querySelector("input");
            const activeElement = document.activeElement;

            if (
              !input ||
              !activeElement ||
              input === activeElement ||
              !activeElement.contains(input)
            ) {
              return;
            }

            if (input.type === "checkbox") {
              input.focus();
            } else {
              input.select();
            }
          },
        }
      : {
          onClick: () => {
            props.enterEdit(dataItem, cellField);
          },
        };

  const clonedProps = { ...props.td.props, ...additionalProps };
  return React.cloneElement(props.td, clonedProps, props.td.props.children);
};

export const RowRender = (props) => {
  const trProps = {
    ...props.tr.props,
    onBlur: () => {
      props.exitEdit();
    },
  };

  return React.cloneElement(props.tr, { ...trProps }, props.tr.props.children);
};

// if (td && cellField) {
//   console.log(td.innerHTML);
//   switch (cellField) {
//     case "name":
//       // Names are in "lastName, firstName" format => show normal format
//       td.innerText = td.innerText.split(",").reverse().join(" ").trim();
//       break;
//     case "region":
//       td.innerText = td.innerText.replace(" Region", "");
//       break;
//     case "location":
//       // Get everything up until the last spaced-delimited section (i.e., the state abbreviation)
//       td.innerText = td.innerText.split(" ").slice(0, -1).join(" ");
//       break;
//     case "manager":
//       // Manager shows employeeID for some reason => remove it, then show the normal format
//       td.innerText =
//         td.innerText.substring(0, td.innerText.indexOf("(")) +
//         td.innerText.substring(td.innerText.indexOf(")") + 1);
//       td.innerText = td.innerText.split(",").reverse().join(" ").trim();
//       break;
//     case "rating":
//       // Make sure anyone after June 30 is "Not Eligible"
//       td.innerText =
//         new Date(td.innerText) > new Date(new Date().getFullYear() + "-06-30")
//           ? "Not Eligible"
//           : td.innerText;
//       break;
//     case "hireDate":
//       // Calculate employee's service years
//       let timeSinceHire = new Date(
//         new Date().getTime() - Date.parse(td.innerText)
//       );

//       // You have to subtract 1970 to get the actual year difference... javascript
//       td.innerText =
//         timeSinceHire.getFullYear() - 1970 < 1
//           ? "<1"
//           : (timeSinceHire.getFullYear() - 1970).toString();
//       break;
//     default:
//       break;
//   }
// }
