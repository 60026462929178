import React from "react";
import { useState, useEffect, ReactElement } from "react";

import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridHeaderCellProps,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import "@progress/kendo-theme-default/dist/all.css";
import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { NumberFormatOptions } from "@progress/kendo-react-intl";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";

import CommentModal from "../Modals/CommentModal";
import RatingDescriptionModal from "../Modals/RatingDescriptionModal";
import { PossibleRatings } from "../../Interfaces/IPossibleRatings";
import { IRateEmployee } from "../../Interfaces/IRateEmployee";
import { IRatingGridProps } from "../../Interfaces/IRatingGridProps";
import "../../../src/styles/ratingGrid.css";
import { CellRender, RowRender } from "./renderers";
import NotEligibleModal from "../Modals/NotEligibleModal";

const EDIT_FIELD = "inEdit";

const possibleRatings: PossibleRatings[] = [
  "Not Eligible",
  "Target",
  "High",
  "Highest",
];

const RoleTypes = {
  Core: "Core",
  Rater: "Rater",
  Observer: "Observer",
  Committee: "Committee",
  Admin: "Admin",
};

const initialSort: Array<SortDescriptor> = [{ field: "nameLabel", dir: "asc" }];

const RatingGrid = (props: IRatingGridProps) => {
  const { data, setData } = props;
  const [filterData, setFilterData] = useState<IRateEmployee[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sort, setSort] = useState(initialSort);

  const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
  const [showRatingDescriptionModal, setShowRatingDescriptionModal] =
    useState<boolean>(false);
  const [showNotEligibleModal, setShowNotEligibleModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<IRateEmployee | undefined>(
    undefined
  );

  useEffect(() => {
    setIsLoading(false);
    if (!data) {
      return;
    }

    if (!props.filter) {
      setFilterData(data);
      return;
    }

    setFilterData(
      data.filter((item) => {
        return item.name.toLowerCase().includes(props.filter);
      })
    );
  }, [data, props.filter]);

  const enterEdit = (dataItem: IRateEmployee, field) => {
    const newData = data.map((item) => ({
      ...item,
      [EDIT_FIELD]: item.id === dataItem.id ? field : undefined,
    }));

    setData(newData);
  };

  const exitEdit = () => {
    const newData = data.map((item) => ({
      ...item,
      [EDIT_FIELD]: undefined,
    }));

    setData(newData);
    saveChanges();
  };

  const saveChanges = () => {
    data.splice(0, data.length, ...data);
  };

  const customCellRender = (
    td: ReactElement<HTMLTableCellElement>,
    props: GridCellProps
  ) => (
    <CellRender
      key={td.key}
      originalProps={props}
      td={td}
      enterEdit={enterEdit}
      editField={EDIT_FIELD}
    />
  );

  const customRowRender = (tr, props) => (
    <RowRender
      key={tr.key}
      originalProps={props}
      tr={tr}
      exitEdit={exitEdit}
      editField={EDIT_FIELD}
    />
  );

  const handleRatingChange = (event: DropDownListChangeEvent) => {
    let selectedItem: IRateEmployee | undefined = undefined;
    const isHighest: boolean = event.value === "Highest";
    const isNotEligible: boolean = event.value === "Not Eligible";

    setData((data: IRateEmployee[]) => {
      return data.map((item: IRateEmployee) => {
        if (item.id === parseInt(event.target.props.id!)) selectedItem = item;

        return item.id === parseInt(event.target.props.id!)
          ? {
              ...item,
              rating: event.value,
              comments: isHighest ? item.comments : "",
              reason: isNotEligible ? item.reason : "",
              bonusAmount:
                event.value === "Not Eligible" ? 0.0 : item.bonusAmount,
            }
          : item;
      });
    });

    if (isHighest) showCommentModalHandler(selectedItem);
    if (isNotEligible) showNotEligibleModalHandler(selectedItem);
  };

  const onCommentEdit = (id: string) => {
    let selectedItem: IRateEmployee | undefined = undefined;

    data.forEach((item: IRateEmployee) => {
      if (item.id === parseInt(id!)) selectedItem = item;
    });

    if (selectedItem) showCommentModalHandler(selectedItem);
  };

  const onReasonEdit = (id: string) => {
    let selectedItem: IRateEmployee | undefined = undefined;

    data.forEach((item: IRateEmployee) => {
      if (item.id === parseInt(id!)) selectedItem = item;
    });

    if (selectedItem) showNotEligibleModalHandler(selectedItem);
  };

  const showFullCommentHandler = (dataItem: IRateEmployee) => {
    setData(
      data.map((item) => {
        return item.id === dataItem.id
          ? { ...item, showFullComment: !item.showFullComment }
          : item;
      })
    );
  };

  const showFullReasonHandler = (dataItem: IRateEmployee) => {
    setData(
      data.map((item) => {
        return item.id === dataItem.id
          ? { ...item, showFullReason: !item.showFullReason }
          : item;
      })
    );
  };

  // * Custom cells expect a <td> tag around the component
  // Needed to add a div surrounding the span in order for
  // onClick to trigger on whitespace
  const ratingList = ({ dataItem }: GridCellProps) => {
    const commentTooLong = dataItem.comments && dataItem.comments.length > 100;
    const comments =
      !dataItem.showFullComment && commentTooLong
        ? dataItem.comments.substring(0, 100)
        : dataItem.comments ?? "";

    const reasonTooLong = dataItem.reason && dataItem.reason.length > 100;
    const reason =
      !dataItem.showFullReason && reasonTooLong
        ? dataItem.reason.substring(0, 100)
        : dataItem.reason ?? "";

    return (
      <td>
        {props.isEditable ? (
          <DropDownList
            key={dataItem.id}
            data={possibleRatings}
            value={dataItem.rating}
            id={dataItem.id.toString()}
            onChange={handleRatingChange}
            disabled={!props.isEditable}
            popupSettings={{ className: "options" }}
          />
        ) : (
          <label>{dataItem.rating}</label>
        )}

        {comments && commentTooLong && (
          <>
            <div>
              <span className="comment mt-2" id={dataItem.id} key={dataItem.id}>
                {comments}
              </span>
            </div>
            <div className="d-flex flex-row pt-1">
              <span
                onClick={() => showFullCommentHandler(dataItem)}
                className="showComment cursorPointer"
              >
                ... Show {dataItem.showFullComment ? "Less" : "More"}
              </span>
              <span
                title="Edit"
                className="k-icon k-i-edit k-i-pencil pencilIcon"
                onClick={() => onCommentEdit(dataItem.id)}
              />
            </div>
          </>
        )}

        {comments && !commentTooLong && (
          <div className="d-flex mt-2 flex-row">
            <span className="comment" id={dataItem.id} key={dataItem.id}>
              {comments}
            </span>
            <span
              title="Edit"
              className="k-icon k-i-edit k-i-pencil pencilIcon"
              onClick={() => onCommentEdit(dataItem.id)}
            />
          </div>
        )}

        {reason && reasonTooLong && (
          <>
            <div>
              <span className="comment mt-2" id={dataItem.id} key={dataItem.id}>
                {reason}
              </span>
            </div>
            <div>
              <span
                onClick={() => showFullReasonHandler(dataItem)}
                className="showComment cursorPointer"
              >
                ... Show {dataItem.showFullComment ? "Less" : "More"}
              </span>
              <span
                title="Edit"
                className="k-icon k-i-edit k-i-pencil pencilIcon pt-2"
                onClick={() => onReasonEdit(dataItem.id)}
              />
            </div>
          </>
        )}

        {reason && !reasonTooLong && (
          <div className="d-flex mt-2 flex-row">
            <span className="comment" id={dataItem.id} key={dataItem.id}>
              {reason}
            </span>
            <span
              title="Edit"
              className="k-icon k-i-edit k-i-pencil pencilIcon"
              onClick={() => onReasonEdit(dataItem.id)}
            />
          </div>
        )}
      </td>
    );
  };

  const showRatingDescriptionHandler = () => {
    setShowRatingDescriptionModal(true);
  };

  const hideRatingDescriptionHandler = () => {
    setShowRatingDescriptionModal(false);
  };

  const ratingHeader = (props: GridHeaderCellProps) => {
    return (
      <div className="ratingHeader">
        <span>{props.title}</span>
        <span className="px-1" />
        <span
          className="k-icon k-i-information k-i-info cursorPointer"
          onClick={showRatingDescriptionHandler}
        />
        {props.children}
      </div>
    );
  };

  const jobTitleHeader = (props: GridHeaderCellProps) => {
    return (
      <a className="k-link" onClick={props.onClick}>
        <div className="jobTitleHeader">
          <span>{props.title}</span>
          {props.children}
        </div>
      </a>
    );
  };

  const yearsOfServiceHeader = (props: GridHeaderCellProps) => {
    return (
      <div className="yearsOfServiceHeader">
        <span>{props.title}</span>
        {props.children}
      </div>
    );
  };

  const yearsOfServiceCell = ({ dataItem }: GridCellProps) => {
    return (
      <td className="yearsOfServiceCell">{dataItem.yearsOfEmploymentLabel}</td>
    );
  };

  const employeeNameCell = ({ dataItem }: GridCellProps) => {
    return (
      <td className="employeeNameColumn">
        <b>{dataItem.nameLabel}</b>
      </td>
    );
  };

  const handleBonusAmountChange = (event: NumericTextBoxChangeEvent) => {
    setData((data) => {
      return data.map((item: IRateEmployee) => {
        return item.id === parseInt(event.target.props.id!)
          ? { ...item, bonusAmount: event.value! }
          : item;
      });
    });
  };

  const formatCurrencyOptions: NumberFormatOptions = {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
  };

  // * Custom cells expect a <td> tag around the component
  // TODO: can't type in a number efficiently - it blurs the input on keypress
  const bonusAmount = ({ dataItem }: GridCellProps) => {
    //const disabledClass = dataItem.rating !== "Highest" ? "numericTextbox" : "";
    const disabledClass = "numericTextbox";
    return (
      <td key={dataItem.key}>
        <NumericTextBox
          key={dataItem.id}
          value={dataItem.bonusAmount}
          id={dataItem.id.toString()}
          onChange={handleBonusAmountChange}
          format={formatCurrencyOptions}
          step={500}
          min={0}
          max={99999}
          defaultValue={0}
          disabled={!props.type.includes(RoleTypes.Admin)}
          className={disabledClass}
        />
      </td>
    );
  };

  const onCommentSubmit = (comment: string) => {
    if (comment) {
      setData((data) => {
        return data.map((item: IRateEmployee) => {
          return item.id === parseInt(selectedItem.id!)
            ? { ...item, comments: comment }
            : item;
        });
      });
    }

    setSelectedItem(undefined);
    setShowCommentModal(false);
  };

  const onCommentCancel = () => {
    if (!selectedItem!.comments) {
      setData((data) => {
        return data.map((item: IRateEmployee) => {
          return item.id === selectedItem!.id ? { ...item, rating: "" } : item;
        });
      });
    }

    setSelectedItem(undefined);
    setShowCommentModal(false);
  };

  const onReasonSubmit = (reason: string) => {
    if (reason) {
      setData((data) => {
        return data.map((item: IRateEmployee) => {
          return item.id === parseInt(selectedItem.id!)
            ? { ...item, reason: reason }
            : item;
        });
      });
    }

    setSelectedItem(undefined);
    setShowNotEligibleModal(false);
  };

  const onReasonCancel = () => {
    if (!selectedItem!.reason) {
      setData((data) => {
        return data.map((item: IRateEmployee) => {
          return item.id === selectedItem!.id ? { ...item, rating: "" } : item;
        });
      });
    }

    setSelectedItem(undefined);
    setShowNotEligibleModal(false);
  };

  const showCommentModalHandler = (item: IRateEmployee | undefined) => {
    if (!item) return;

    setSelectedItem(item);
    setShowCommentModal(true);
  };

  const showNotEligibleModalHandler = (item: IRateEmployee | undefined) => {
    if (!item) return;

    setSelectedItem(item);
    setShowNotEligibleModal(true);
  };

  // ! Only Admin can edit "Bonus Amount" field
  // ! Everyone else can see it after it's been submitted (on December 12)
  // ! Read only after December 12 (except for Admin)
  return (
    <>
      {showCommentModal && selectedItem && (
        <CommentModal
          show={showCommentModal}
          onCancel={onCommentCancel}
          onSubmit={onCommentSubmit}
          selectedItem={selectedItem}
        />
      )}

      {showNotEligibleModal && selectedItem && (
        <NotEligibleModal
          show={showNotEligibleModal}
          onCancel={onReasonCancel}
          onSubmit={onReasonSubmit}
          selectedItem={selectedItem}
        />
      )}

      {showRatingDescriptionModal && (
        <RatingDescriptionModal
          show={showRatingDescriptionModal}
          onCancel={hideRatingDescriptionHandler}
        />
      )}

      {isLoading ? (
        <div className="loadingSpinnerDiv">
          <div className="lds-dual-ring" />
        </div>
      ) : (
        <Grid
          data={filterData && orderBy(filterData, sort)}
          dataItemKey={"id"}
          cellRender={customCellRender}
          rowRender={customRowRender}
          editField={EDIT_FIELD}
          sortable={true}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
          scrollable={"none"}
        >
          <Column
            field="nameLabel"
            title="Employee"
            editable={false}
            cell={employeeNameCell}
          />
          <Column
            field="jobTitle"
            title="Title"
            editable={false}
            width={30}
            headerCell={jobTitleHeader}
          />
          <Column field="managerLabel" title="Manager" editable={false} />
          <Column field="divisionLabel" title="Company" editable={false} />
          <Column field="regionLabel" title="Region" editable={false} />
          <Column field="locationLabel" title="Location" editable={false} />
          <Column
            field="yearsOfEmploymentLabel"
            title="Years of Service"
            editable={false}
            headerCell={yearsOfServiceHeader}
            width={10}
            cell={yearsOfServiceCell}
          />
          <Column
            field="rating"
            title="Rating"
            editable={props.isEditable}
            cell={ratingList}
            width={"142px"}
            headerCell={ratingHeader}
          />
          {props.type === RoleTypes.Admin && (
            <Column
              field="bonusAmount"
              title="Bonus Amount"
              editable={props.isEditable}
              cell={bonusAmount}
            />
          )}

          {/*{!(props.type === RoleTypes.Rater) && (*/}
          {/*  <Column */}
          {/*    field="bonusAmount"*/}
          {/*    title="Bonus Amount"*/}
          {/*    editable={props.isEditable}*/}
          {/*    cell={bonusAmount}*/}
          {/*  />*/}
          {/*)}*/}
        </Grid>
      )}
    </>
  );
};

export default RatingGrid;
