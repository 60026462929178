import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { IRatingDescriptionModalProps } from "../../Interfaces/IRatingDescriptionModalProps";
import "../../styles/ratingDescriptionModal.css";

interface IRoleDescriptionModalProps extends IRatingDescriptionModalProps {
  role: string | undefined;
}

const resolveRole = (role: string | undefined) => {
  if (!role) return;

  if (role === "Rater")
    return {
      title: "Rate Employees Assigned to Me",
      description: (
        <td>
          <ul>
            <li className="text-start">
              In this tab, you are responsible for rating the employees listed
              below in one of four categories
            </li>
            <li className="text-start">
              If you are a Regional Manager, please coordinate with each
              employee's Subsidiary President prior to submitting (and thereby
              finalizing) your ratings - direct managers are also listed for
              easy reference
            </li>
          </ul>
        </td>
      ),
    };

  if (role === "Observer")
    return {
      title: "View Ratings within My Organization",
      description: (
        <td className="text-start">
          In this tab, you have viewing rights only, which allows you to see
          ratings of employees within your organization performed by others,
          likely a Regional Manager
        </td>
      ),
    };
};

const RoleDescriptionModal = (props: IRoleDescriptionModalProps) => {
  const [show, setShow] = useState<boolean>(props.show);
  const { title, description } = resolveRole(props.role);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const onCancelHandler = () => {
    setShow(false);
    props.onCancel();
  };

  return (
    <>
      <Modal show={show} onHide={onCancelHandler} centered size="lg">
        <Modal.Body>
          <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
              <table className="table-bordered m-auto table align-middle">
                <tbody>
                  <tr>
                    <td>{title}</td>
                    {description}
                  </tr>
                </tbody>
              </table>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RoleDescriptionModal;
