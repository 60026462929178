import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Button } from "react-bootstrap";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { BsSearch } from "react-icons/bs";

import RatingGrid from "./RatingGrid";
import RoleDescriptionModal from "../Modals/RoleDescriptionModal";
import RatingDescriptionModal from "../Modals/RatingDescriptionModal";
import { IRateEmployee } from "../../Interfaces/IRateEmployee";
import {
  IRequestDataSchema,
  ISubmitDataSchema,
} from "../../Interfaces/IDataSchemas";
import "../../../src/styles/ratingIndex.css";

const RoleTypes = {
  Core: "Core",
  Rater: "Rater",
  Observer: "Observer",
  Committee: "Committee",
  Admin: "Admin",
};

type Response = {
  data: IRequestDataSchema;
};

type Props = {
  year: number;
  userId?: string;
};

const tabLabelResolver = (tabName: string) => {
  switch (tabName) {
    case "Rater":
      return "Rate Employees Assigned to Me";
    case "Observer":
      return "View Ratings Within My Organization";
    default:
      return tabName;
  }
};

const Index = ({ userId, year = 2023 }: Props) => {
  const { oktaAuth } = useOktaAuth();

  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 30,
    name: "React Component Engaged Time (seconds)",
    sampleCount: 1,
  };
  const additionalProperties = { "Component Name": "Index" };
  appInsights.trackMetric(metricData, additionalProperties);

  const [rateList, setRateList] = useState<IRateEmployee[]>();
  const [observeList, setObserveList] = useState<IRateEmployee[]>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showRatingDescriptionModal, setShowRatingDescriptionModal] =
    useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<string>();

  const [showSaveSuccess, setShowSaveSuccess] = useState<boolean>(false);
  const [showSaveFailure, setShowSaveFailure] = useState<boolean>(false);
  const [showSubmitSuccess, setShowSubmitSuccess] = useState<boolean>(false);
  const [showSubmitFailure, setShowSubmitFailure] = useState<boolean>(false);

  const [filterText, setFilterText] = useState<string>("");
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [showRoleDescriptionModal, setShowRoleDescriptionModal] =
    useState<boolean>(false);

  const postData = async (submitData: ISubmitDataSchema) => {
    debugger;
    const formurl = process.env.REACT_APP_ENDPOINT_URL + "/api/employeerating";
    const accessToken = await oktaAuth.getAccessToken();

    await axios
      .post(formurl, submitData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        debugger;
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getData = useCallback(async () => {
    setIsLoading(true);
    const formurl =
      process.env.REACT_APP_ENDPOINT_URL +
      `/api/employeerating/${year}/${userId}`;
    const accessToken = await oktaAuth.getAccessToken();
    await axios
      .get<Response>(formurl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res: any) => {
        setUserRoles(res.data.roles);
        setActiveTab(res.data.roles[0]);

        //setData(res.data);
        var rates = preprocessData(res.data.rateList);
        var observer = preprocessData(res.data.observeList);

        setRateList(rates);
        setObserveList(observer);
        setIsSubmitted(res.data.isSubmitted);
      })
      .catch((err) => console.log(err));
  }, [year, userId]);

  useEffect(() => {
    setIsLoading(true);
    getData().then(() => setIsLoading(false));
  }, [userId, year]);

  const preprocessData = (list: IRateEmployee[]) => {
    list.forEach((item) => {
      item.regionLabel = item.region.replace(" Region", "");

      // Get everything up until the last spaced-delimited section (i.e., the state abbreviation)
      item.locationLabel = item.location.split(" ").slice(0, -1).join(" ");

      // Names are in "lastName, firstName" format => show normal format
      // turns out some people also have Jr/Sr in their names...
      const nameParts = item.name.split(",");
      item.nameLabel =
        nameParts.length > 2
          ? `${nameParts[2].trim()} ${nameParts[0].trim()} ${nameParts[1].trim()}`
          : `${nameParts[1].trim()} ${nameParts[0].trim()}`;

      const managerParts = item.manager.split(",");
      item.managerLabel =
        managerParts.length > 2
          ? `${managerParts[2].trim()} ${managerParts[0].trim()} ${managerParts[1].trim()}`
          : `${managerParts[1].trim()} ${managerParts[0].trim()}`;

      // Make sure anyone after June 30 is "Not Eligible"
      item.rating =
        new Date(item.hireDate) > new Date(new Date().getFullYear() + "-06-30")
          ? "Not Eligible"
          : item.rating;

      // May need to abbreviate Company to match Figma mockup
      item.divisionLabel = item.division;

      let timeSinceHire = new Date(
        new Date().getTime() - Date.parse(item.hireDate)
      );

      // You have to subtract 1970 to get the actual year difference... javascript
      item.yearsOfEmploymentLabel =
        timeSinceHire.getFullYear() - 1970 < 1
          ? "<1"
          : (timeSinceHire.getFullYear() - 1970).toString();

      item.showFullComment = false;
      item.showFullReason = false;

      item.comments = item.comments ?? "";
      item.reason = item.reason ?? "";
    });

    return list;
  };

  const onSubmitHandler = (isSubmitted: boolean) => {
    if (!rateList) return;

    let filterList = rateList.filter((x) => x.rating);

    // Make sure we submit nulls instead of empty strings
    filterList = filterList.map((item) => {
      if (item.comments === "") {
        if (item.reason === "")
          return { ...item, comments: null, reason: null };
        return { ...item, comments: null };
      }

      if (item.reason === "") return { ...item, reason: null };

      return item;
    });

    const submitData: ISubmitDataSchema = {
      rateList: filterList,
      year: year,
      employeeId: userId!,
      isSubmitted: isSubmitted,
    };
    setIsSubmitting(true);

    postData(submitData)
      .then((response: any) => {
        console.log(response);
        setIsSubmitting(false);

        if (isSubmitted) {
          setIsSubmitted(true);
          setShowSubmitSuccess(true);
          setShowSubmitFailure(false);
        } else {
          setShowSaveSuccess(true);
          setShowSaveFailure(false);
        }
      })
      .catch((response: any) => {
        console.log(response);
        if (isSubmitted) {
          setShowSubmitSuccess(false);
          setShowSubmitFailure(true);
        } else {
          setShowSaveSuccess(false);
          setShowSaveFailure(true);
        }
      });
  };

  const onSearchEmployee = (event) => {
    const debounce = setTimeout(
      () => setFilterText(event.target.value.toLowerCase()),
      300
    );
    return () => clearTimeout(debounce);
  };

  const showRoleDescriptionHandler = () => {
    setShowRoleDescriptionModal(true);
  };

  const hideRoleDescriptionHandler = () => {
    setShowRoleDescriptionModal(false);
  };

  const hideRatingDescriptionHandler = () => {
    setShowRatingDescriptionModal(false);
  };

  const RatingDescription = () => {
    return (
      <div className="d-flex align-items-center pt-2">
        <ul>
          <li>
            Please select the appropriate performance rating for the employees
            below. You are encouraged to seek input from other stakeholders
            (Direct Supervisor, Subsidiary Presidents, Customers, etc.) to
            determine ratings.
          </li>
          <li>
            The expectation is most employees will receive the Target rating
            (50% or more), while about 35% will be rated as High, 10% as
            Highest, and 5% as Not Eligible. Employees who are rated Highest
            will require a 2-3 sentence justification, and Not Eligible will
            require you to choose a reason.{" "}
            <span
              className="cursorPointer clickHereUnderline"
              onClick={() => setShowRatingDescriptionModal(true)}
            >
              Click here to view the description of each rating.
            </span>
          </li>
          <li>
            <u>
              Ratings should be submitted by COB
              <b> Friday, November 10th.</b>
            </u>
          </li>
          <li>
            Final bonus amounts will be visible to you on
            <b> Monday, December 11th</b> for communication to employees.
          </li>
          <li>
            Bonuses will be paid on
            <b> Friday, December 15th.</b>
          </li>
          <li>
            <b>
              <i>
                Do not communicate bonus amounts to employees until finalized by
                the Bonus Committee.
              </i>
            </b>
          </li>
        </ul>
      </div>
    );
  };

  const ObservingDescription = () => {
    return (
      <div className="d-flex align-items-center pt-2">
        <ul>
          <li>
            Please see below ratings assigned to employees within your
            organization. Raters (typically Functional or Regional Managers) are
            encouraged to seek input from other stakeholders such as yourself.{" "}
            <span
              className="cursorPointer clickHereUnderline"
              onClick={() => setShowRatingDescriptionModal(true)}
            >
              Click here to view the description of each rating.
            </span>
          </li>
          <li>
            <u>
              If you have questions/feedback on the ratings, please communicate
              with the cognizant Regional or Functional Manger prior to COB
              <b> Friday, November 10th. </b>
            </u>
          </li>
          <li>
            Ratings should be submitted by COB
            <b> Friday, November 10th.</b>
          </li>
          <li>
            Final bonus amounts will be visible to you on
            <b> Monday, December 11th.</b>
          </li>
          <li>
            Bonuses will be paid on
            <b> Friday, December 15th.</b>
          </li>
          <li>
            <b>
              <i>
                Do not communicate bonus amounts to employees until finalized by
                the Bonus Committee.
              </i>
            </b>
          </li>
        </ul>
      </div>
    );
  };

  // ! Change nonStickyClasses reference to stickyClasses for the sticky button footer
  // let stickyClasses =
  //   "d-flex justify-content-end navbar sticky-bottom mb-5 pt-5 buttonFooter";
  let nonStickyClasses = "d-flex justify-content-end py-5 buttonFooter";

  return (
    <>
      <div className="bodyWithoutFooter">
        {isLoading ? (
          <div className="loadingSpinnerDiv">
            <div className="lds-dual-ring" />
          </div>
        ) : (
          <>
            {showRoleDescriptionModal && (
              <RoleDescriptionModal
                show={showRoleDescriptionModal}
                onCancel={hideRoleDescriptionHandler}
                role={activeTab}
              />
            )}

            {showRatingDescriptionModal && (
              <RatingDescriptionModal
                show={showRatingDescriptionModal}
                onCancel={hideRatingDescriptionHandler}
              />
            )}

            <Nav tabs>
              {userRoles.length > 1 &&
                userRoles.map((role) => {
                  return (
                    <NavItem key={role}>
                      <NavLink
                        className={
                          activeTab === role ? "active selected-tab tab" : "tab"
                        }
                        key={role}
                        value={role}
                        onClick={() => setActiveTab(role)}
                      >
                        {tabLabelResolver(role)}
                        <span className="px-1" />
                        <span
                          className="k-icon k-i-information k-i-info cursorPointer"
                          onClick={showRoleDescriptionHandler}
                        />
                      </NavLink>
                    </NavItem>
                  );
                })}
            </Nav>

            {userRoles.includes("Rater") && activeTab === "Rater" && (
              <RatingDescription />
            )}

            {userRoles.includes("Observer") && activeTab === "Observer" && (
              <ObservingDescription />
            )}

            <div className="d-flex flex-column">
              <div className="d-flex justify-content-end w-100 pb-5">
                <input
                  type="text"
                  placeholder="Employee Name Search"
                  onChange={onSearchEmployee}
                  className={"i-placeholder border"}
                />
                <Button
                  style={{
                    minWidth: "0px",
                    borderRadius: "0px",
                    backgroundColor: "#eee",
                    height: "fit-content",
                    borderColor: "#424242",
                  }}
                  type="button"
                  disabled={filterText.length === 0}
                >
                  <BsSearch style={{ color: "#333" }} />
                </Button>
              </div>
              {userRoles.length === 1 && (
                <div className="d-flex justify-content-end w-100 mb-5" />
              )}
            </div>

            <>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={RoleTypes.Core}>
                  <p>You shouldn't be here.</p>
                </TabPane>
                <TabPane tabId={RoleTypes.Rater}>
                  <RatingGrid
                    data={rateList}
                    setData={setRateList}
                    filter={filterText}
                    type={RoleTypes.Rater}
                    isEditable={!isSubmitted && !isSubmitting}
                  />
                </TabPane>
                <TabPane tabId={RoleTypes.Observer}>
                  <RatingGrid
                    data={observeList}
                    setData={setObserveList}
                    filter={filterText}
                    type={RoleTypes.Observer}
                    isEditable={false}
                  />
                </TabPane>
                <TabPane tabId={RoleTypes.Committee}>
                  <RatingGrid
                    data={observeList}
                    setData={setObserveList}
                    filter={filterText}
                    type={RoleTypes.Committee}
                    isEditable={false}
                  />
                </TabPane>
                <TabPane tabId={RoleTypes.Admin}>
                  <TabPane tabId={RoleTypes.Committee}>
                    <RatingGrid
                      data={[]}
                      setData={() => {
                        return;
                      }}
                      filter={""}
                      type={RoleTypes.Admin}
                      isEditable={false}
                    />
                  </TabPane>
                </TabPane>
              </TabContent>
            </>
          </>
        )}
      </div>

      {(activeTab === "Rater" || activeTab === "Admin") && (
        <div className={nonStickyClasses}>
          <div className="d-flex buttonSection">
            <div className="px-4">
              {showSaveSuccess && (
                <span className="saveSubmitMessage successMessage">
                  Save successful!
                </span>
              )}
              {showSaveFailure && (
                <span className="saveSubmitMessage failureMessage">
                  Save failed!
                </span>
              )}
              {showSubmitSuccess && (
                <span className="saveSubmitMessage successMessage">
                  Submit successful!
                </span>
              )}
              {showSubmitFailure && (
                <span className="saveSubmitMessage failureMessage">
                  Submit failed!
                </span>
              )}

              <button
                type="button"
                className="btn btnSave"
                onClick={() => onSubmitHandler(false)}
                disabled={isSubmitted || isSubmitting}
              >
                Save
              </button>
            </div>
            <div>
              <button
                type="button"
                className={
                  isSubmitted
                    ? "btn btnSave"
                    : rateList.every((item) => item.rating)
                    ? "btn btnSubmit"
                    : "btn btnSave"
                }
                disabled={
                  isSubmitted ||
                  isSubmitting ||
                  !rateList.every((item) => item.rating)
                }
                onClick={() => onSubmitHandler(true)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
