import React, { useContext } from "react";
import Index from "./Rating/Index";
import { Alert } from "reactstrap";
import { ChangeEvent, useEffect, useState } from "react";
import { ISimulatedUser } from "../components/Rating/dummyData";
import { simulatedUserList } from "../components/Rating/dummyData";
import { userContext } from "./context/UserContext";

const Home = () => {
  const year = new Date().getFullYear();
  const user: ISimulatedUser[] = simulatedUserList;
  const [userId, setUserId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const userInfo = useContext(userContext);

  const onSimUserChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setUserId(event.target.value);
  };

  const renderSwitch = (param) => {
    switch (param) {
      case "development":
        return "info";
      case "staging":
        return "warning";
      case "testing":
        return "danger";
      default:
        return "primary";
    }
  };

  useEffect(() => {
    setUserId(userInfo?.bamboohrId);
    setIsLoading(false);
  }, []);

  // ? Not used... for now
  // const yearDropdown = () => {
  //   return (
  //     <div className="d-flex align-items-end w-100">
  //       <Form.Select
  //         className="yearDropdown"
  //         title="years"
  //         name="years"
  //         defaultValue={currentYear}
  //       >
  //         <option value={currentYear}>{year}</option>
  //         <option value={2021}>{2021}</option>
  //       </Form.Select>
  //     </div>
  //   );
  // };

  return (
    <>
      {process.env.REACT_APP_NODE_ENV !== "production" && (
        <>
          <Alert color={renderSwitch(process.env.REACT_APP_NODE_ENV)}>
            You are running this application in{" "}
            <b>{process.env.REACT_APP_NODE_ENV}</b> mode.
            {process.env.REACT_APP_NODE_ENV !== "production" && (
              <>
                <select
                  defaultValue={user[0].bambooHrId}
                  onChange={(simUser) => onSimUserChange(simUser)}
                >
                  {simulatedUserList.map((simUser: ISimulatedUser) => {
                    return (
                      <option
                        key={simUser.bambooHrId}
                        value={simUser.bambooHrId}
                      >
                        {simUser.name}
                      </option>
                    );
                  })}
                </select>
              </>
            )}
          </Alert>
        </>
      )}

      {!isLoading && userId && (
        <>
          <Index year={2023} userId={userId} />
        </>
      )}
    </>
  );
};

export default Home;
