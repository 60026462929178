import React from "react";

/* ! react-router-dom HAS TO BE VERSION 5, NOT 6 */
import { Route, Switch, useHistory } from "react-router-dom";

import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";

import config from "./oktaConfig";
import { UserContext } from "./components/context/UserContext";

import AppRoutes from "./AppRoutes";
import { Layout } from "./components/Layout";
import "./northwind.css";
import Home from "./components/Home";
import "../src/styles/globals.css";

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path="/login/callback" component={LoginCallback} />
        <UserContext>
          <Layout>
            <SecureRoute path="/" exact={true} component={Home} />
          </Layout>
        </UserContext>
      </Switch>
    </Security>
  );
};
export default App;
